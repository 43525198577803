export default class {
  static spec(projects) {
    if (projects.length > 0) {
      return {
        "view": "panels/list",
        "id": "project_list",
        "styleClasses": ["card"],
        "width": "matchParent",
        "sections": [
          {
            "rows": projects.map((project) => {
              return this.rowSpec(project)
            }),
            "footer": this.footerSpec()
          }
        ]
      };
    }
    return {
      "view": "panels/list",
      "width": "matchParent",
      "sections": [
        {
          "header": {
            "padding": {
              "top": 20, "bottom": 10, "left": 20, "right": 20
            },
            "childViews": [
              {
                "view": "h4",
                "text": "You don't have a project yet."
              },
              {
                "view": "spacer",
                "height": 8
              },
              {
                "view": "panels/horizontal",
                "childViews": [
                  {
                    "view": "image",
                    "height": 14,
                    "url": $tpu.icons.shapeupAlert,
                    // "badge": {
                    //   "text": "1",
                    // },
                  },
                  {
                    "view": "spacer",
                    "width": 4
                  },
                  {
                    "view": "label",
                    "text": "Start by creating your first project."
                  },
                ]
              }
            ]
          },
          "footer": this.footerSpec()
        }
      ]
    };
  }

  static rowSpec(project) {
    let subtitle = null, onClick = null
    const scopeCards = project.transient.scopeCards
    if (project.dataMissing) {
      subtitle = '<data missing>'
      onClick = {
        "action": "dialogs/alert",
        "message": "This could happen because:\n" +
          "1) Someone previously uninstalled and cleared the Power-Up data, or\n" +
          "2) Someone deleted the project, but the operation didn't complete due to various reasons.\n\n" +
          "In either case, please delete this project manually."
      }
    } else {
      if (scopeCards) {
        const completedCount = scopeCards.reduce((acc, cur) => {
          return cur.shapeUp.hillPointX >= 100 ? acc + 1 : acc
        }, 0)
        subtitle = `${completedCount}/${scopeCards.length} completed`
      } else {
        // This could happen initially when the accessory has not been loaded.
        subtitle = 'Loading...'
      }
      onClick = {
        "action": "windows/open",
        "url": `${$tpu.urls.project(project.id)}`
      }
    }

    return {
      "template": "thumbnail",
      "styleClasses": ["project"],
      "id": project.id,
      "title": project.name,
      "subtitle": subtitle,
      "rightButtons": [
        {
          "styleClasses": $tpu.styles.iconButtonClasses(),
          "icon": {
            "material": {
              // "name": "chevron_right"
              "name": "edit"
            }
          },
          "onClick": this.editAction(project),
          "type": "button"
        },
      ],
      "onClick": onClick
    }
  }

  static editAction(project) {
    const actionViews = [
      {
        "view": "h4",
        "text": "Actions"
      },
      {
        "view": "spacer",
        "height": 4
      },
      {
        "view": "button",
        "styleClasses": ["tonal", "small", "error"],
        "icon": { "material": { "name": $tpu.keys.icnDestroy() } },
        "text": "Delete",
        "width": "matchParent",
        "onClick": {
          "action": "sheets/select",
          "message": "This action cannot be undone. Are you sure?",
          "buttons": [
            {
              "text": "Yes, delete permanently",
              "onClick": {
                "action": "commands/custom",
                "name": "projects/destroy",
                "properties": {
                  "projectId": project.id
                }
                // "onSuccess": {
                //   "action": "dialogs/close",
                //   "onClose": {
                //     "action": "windows/reload"
                //   }
                // },
              },
              "type": "button"
            }
          ]
        }
      }
    ]

    return {
      "action": "dialogs/show",
      "closeOnBlur": true,
      "body": {
        "padding": {
          "top": 18,
          "left": 20,
          "right": 20,
          "bottom": 22
        },
        "childViews": [
          {
            "view": "panels/column-v1",
            "xs": {
              "cols": 8
            },
            "childViews": [
              {
                "view": "panels/form",
                "width": "matchParent",
                "url": $tpu.urls.project(project.id),
                "method": "patch",
                "childViews": [
                  {
                    "view": "h4",
                    "text": "Edit Project"
                  },
                  {
                    "view": "spacer",
                    "height": 6
                  },
                  {
                    "view": "fields/text",
                    "styleClasses": ["outlined", "compact"],
                    "width": "matchParent",
                    "name": "project[name]",
                    "label": "Project name",
                    "value": project.name
                  },
                  {
                    "view": "spacer",
                    "height": 4
                  },
                  {
                    "view": "fields/submit",
                    "text": "Save"
                  }
                ]
              }
            ]
          },
          {
            "view": "panels/column-v1",
            "xs": {
              "cols": 1
            }
          },
          {
            "view": "panels/column-v1",
            "xs": {
              "cols": 3
            },
            "childViews": actionViews
          }
        ]
      }
    }
  }

  static footerSpec() {
    return {
      "padding": {
        "top": 10, "bottom": 10, "left": 20, "right": 20
      },
      "childViews": [
        {
          "view": "panels/split",
          "width": "matchParent",
          "center": {
            "height": "matchParent",
            "childViews": [
              {
                "view": "panels/form-v1",
                "url": $tpu.urls.projects(),
                "method": "post",
                "childViews": [
                  {
                    "view": "panels/horizontal",
                    "height": "matchParent",
                    "align": "top",
                    "childViews": [
                      {
                        "view": "fields/text-v1",
                        "width": 240,
                        // "width": "matchParent",
                        "styleClasses": [
                          "outlined",
                          "compact"
                        ],
                        "maxLength": 50,
                        // "rightIcon": {
                        //   "material": {
                        //     "name": "keyboard_return"
                        //   }
                        // },
                        "name": "project[name]",
                        "label": "Add a project",
                        "placeholder": "Project name",
                        "hint": "E.g. Calendar feature, V1.0 Launch",
                        "id": "project_name"
                      },
                      {
                        "view": "spacer",
                        "width": 8
                      },
                      {
                        "view": "fields/submit",
                        "styleClasses": ["tonal"],
                        "text": "Save"
                      },
                    ]
                  }
                ]
              }
            ]
          },
          "right": {
            "height": "matchParent",
            "childViews": [
              // {
              //   "view": "panels/horizontal",
              //   "height": "matchParent",
              //   "align": "middle",
              //   "childViews": [
              //     {
              //       "view": "fields/select-v1",
              //       "styleClasses": [
              //         "outlined",
              //         "dense"
              //       ],
              //       "disableDirtyCheck": true,
              //       "name": "url",
              //       "value": this.filterUrl(this.spec.statusFilter),
              //       "options": [
              //         {
              //           "value": this.filterUrl('active'),
              //           "text": "Active"
              //         },
              //         {
              //           "value": this.filterUrl('archived'),
              //           "text": "Archive"
              //         },
              //       ],
              //       // TODO: Try this
              //       // "onChange": {
              //       //   "action": "windows/open",
              //       // },
              //       // "paramNameForFormData": "url",
              //       // "paramNameForFormData": "formData",
              //       "onChange": {
              //         "action": "commands/custom",
              //         "name": "projects/index",
              //       }
              //     }
              //   ]
              // }
            ]
          }
        }
      ]
    }
  }
}
